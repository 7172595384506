import { firebaseGetPlacedBets } from '@/firebase/utils'
import { errorMsg, successMsg } from '@/utils/toast'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'
import { handleWarningModal } from './wallet'

export const placeBet = createAsyncThunk(
  'bets/placeBet',
  async (request, thunkAPI) => {
    try {
      const res = await axios.post('/api/bets/place', request)
      if (res && res?.data) {
        thunkAPI.dispatch(getPlacedLiveGames({ type: 'guest', uid: null }))
        if (res?.data?.uid)
          thunkAPI.dispatch(getPlacedBets({ type: 'user', uid: res.data.uid }))
        thunkAPI.dispatch(clearBetSlips())
        thunkAPI.dispatch(toggleBetSlipsMobile(false))
        return res
      } else throw res?.error
    } catch (error) {
      thunkAPI.dispatch(filterBetSlips(error.response.data))
      if (error.response.data?.limit) {
        thunkAPI.dispatch(
          handleWarningModal({
            warningModal: true,
            limitLink: error.response.data?.limit,
          }),
        )
      }
      return thunkAPI.rejectWithValue(error)
    }
  },
)
export const getPlacedBets = createAsyncThunk(
  'bets/getPlacedBets',
  async (request, thunkAPI) => {
    try {
      const res = await firebaseGetPlacedBets(request)
      if (res && !res?.error) return res
      else throw res?.error
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getPlacedLiveGames = createAsyncThunk(
  'bets/getPlacedLiveGames',
  async (request, thunkAPI) => {
    try {
      const res = await firebaseGetPlacedBets(request)
      if (res && !res?.error) return res
      else throw res?.error
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const getRandomNumber = () => Math.floor(Math.random() * 10000)
const getOddId = ({ odd, matchId }) => {
  const currentOddName = odd?.name
    ?.replace(odd?.bet_points?.toString(), '')
    ?.trim()
    ?.replace('+', '')
    ?.trim()
    ?.toLowerCase()
  const currentMarket = odd.market_name?.toLowerCase()?.trim()
  const id = `${matchId}-${currentMarket}-${currentOddName}`
  return id
}

const getAllGamesOdds = (games) => {
  const allOdds = {}
  for (const [sport, leagues] of Object.entries(games || {})) {
    for (const [league, matches] of Object.entries(leagues || {})) {
      for (const match of matches) {
        for (const odd of match?.odds || []) {
          const tempId = getOddId({ odd, matchId: match.id })
          allOdds[tempId] = odd
        }
      }
    }
  }
  return allOdds
}

const handleNonLiveSlips = (slips, newSlips, updatedOdds, liveUpdating) => {
  slips?.forEach((slip) => {
    const tempId = getOddId({ odd: slip, matchId: slip?.match?.id })
    const newSlip = updatedOdds[tempId]
    if (newSlip) {
      if (newSlip?.is_live && !slip?.is_live) {
        newSlips.push({
          ...slip,
          isSettled: true,
        })
      } else {
        newSlips.push({ ...slip, ...newSlip, isSettled: false })
      }
    } else {
      if (liveUpdating) {
        if (slip?.is_live === false) {
          newSlips.push(slip)
        } else {
          newSlips.push({
            ...slip,
            isSettled: true,
          })
        }
      } else {
        if (slip?.is_live === true) {
          newSlips.push(slip)
        } else {
          newSlips.push({
            ...slip,
            isSettled: true,
          })
        }
      }
    }
  })
}

const handleLiveSlips = (slips, updatedOdds, newSlips) => {
  slips?.forEach((slip) => {
    const tempId = getOddId({ odd: slip, matchId: slip?.match?.id })
    const newSlip = updatedOdds[tempId]
    if (
      Number(newSlip?.price) === Number(slip?.price) &&
      Number(newSlip?.bet_points) === Number(slip?.bet_points)
    ) {
      newSlips.push({ ...slip, ...newSlip, isSettled: false })
    }
  })
}

const oddsHandler = ({ oldBetSlips, allOdds: updatedOdds, liveUpdating }) => {
  const newSlips = []
  if (oldBetSlips?.acceptAnyOdds) {
    handleNonLiveSlips(
      oldBetSlips?.betSlips,
      newSlips,
      updatedOdds,
      liveUpdating,
    )
  } else {
    handleLiveSlips(oldBetSlips?.betSlips, updatedOdds, newSlips)
  }
  return newSlips
}

export const checkIfGamesOddIsChanged = (newGames) => (dispatch, getState) => {
  const betSlips = {
    betSlips: getState().betSlips?.slips,
    acceptAnyOdds: getState().bets.acceptAnyOdds,
  }
  const oldBetSlips = Object.assign({}, betSlips)
  if (!oldBetSlips.betSlips?.length) return

  const allOdds = getAllGamesOdds(newGames)
  const updatedSlips = oddsHandler({
    oldBetSlips,
    allOdds,
    liveUpdating: false,
  })
  dispatch(updateBetSlips(updatedSlips))
}

export const checkIfLiveGamesOddIsChanged =
  (newLiveGames) => (dispatch, getState) => {
    const betSlips = {
      betSlips: getState().betSlips?.slips,
      acceptAnyOdds: getState().bets.acceptAnyOdds,
    }

    const oldBetSlips = Object.assign({}, betSlips)
    if (!oldBetSlips.betSlips?.length || !newLiveGames) return

    const allOdds = getAllGamesOdds(newLiveGames)

    const liveUpdateSlips = oddsHandler({
      oldBetSlips,
      allOdds,
      liveUpdating: true,
    })
    dispatch(updateBetSlips(liveUpdateSlips))
  }

const getInitalSlips = () => {
  let slips = Cookies.get('slips')
  if (slips) {
    slips = JSON.parse(slips)
    return slips
  }
  return []
}

const setSlipsInCookies = (slips) => {
  Cookies.remove('slips')
  if (slips.length > 0) {
    Cookies.set('slips', JSON.stringify(slips))
  }
}

const betSlipsSlice = createSlice({
  name: 'betSlips',
  initialState: {
    slips: getInitalSlips(),
    // Render Sports
    rendering: getRandomNumber(),
    // Will be american, decimal, fractional or percentage
    oddsDisplayType: 'american',
    viewType: 'compact',
    menuDisplayType: 'register',
    // Placed Bets
    allUserBets: [],
    totalBetPrice: '',
    placedBets: [],
    placedLiveGames: [],
    fetchBets: false,
    pastBets: [],
    betslipsOpen: true,
    betslipsOpenMobile: false,
    betPlaceLoad: false,
    authError: false,
    isParlay: false,
    parlaySlips: [],
    parlayBetPrice: 0,
  },
  reducers: {
    changeOddsDisplayType: (state, action) => {
      state.oddsDisplayType = action.payload
    },
    changeViewsDisplayType: (state, action) => {
      state.viewType = action.payload
    },
    changeMenuDisplayType: (state, action) => {
      state.menuDisplayType = action.payload
    },
    addBetSlip: (state, action) => {
      state.slips.push(action.payload)
    },
    setIsParlay: (state, action) => {
      state.isParlay = action.payload
    },
    removeBetSlipSecondary: (state, action) => {
      const index = state.slips.findIndex((slip) => slip.id === action.payload)
      state.slips.splice(index, 1)
      state.rendering = getRandomNumber()
      setSlipsInCookies(state.slips)
    },
    removeBetSlip: (state, action) => {
      const index = state.slips.findIndex((slip) => slip.id === action.payload)
      state.slips.splice(index, 1)
      setSlipsInCookies(state.slips)
    },
    updateSingleBetPrice: (state, action) => {
      const index = state.slips.findIndex(
        (slip) => slip.id === action.payload?.id,
      )
      if (index > -1) {
        state.slips[index] = Object.assign(state.slips[index], action.payload)
        setSlipsInCookies(state.slips)
      }
    },
    updateBetSlips: (state, action) => {
      state.slips = action.payload
      state.rendering = getRandomNumber()
      setSlipsInCookies(state.slips)
    },
    updateParlaySlips: (state, action) => {
      state.parlaySlips = action.payload
      state.rendering = getRandomNumber()
    },
    updateParlayBetPrice: (state, action) => {
      state.parlayBetPrice = action.payload
    },
    filterBetSlips: (state, action) => {
      const matchIds = action.payload.betMatchIds
      if (matchIds?.length) {
        const filteredSlips = state.slips.filter(
          (bet) => !matchIds.includes(bet.match.id),
        )
        state.slips = filteredSlips
        state.rendering = getRandomNumber()
        setSlipsInCookies(state.slips)
      }
    },
    clearBetSlips: (state) => {
      state.slips.length = 0
      state.authError = false
      state.rendering = getRandomNumber()
      setSlipsInCookies(state.slips)
    },

    clearBetsData: (state) => {
      state.rendering = getRandomNumber()
      state.totalBetPrice = ''
    },
    updateTotalBetPrice: (state, action) => {
      state.totalBetPrice = action.payload
    },
    addNoteToSingle: (state, action) => {
      const index = state.slips.findIndex(
        (slip) => slip.id === action.payload?.id,
      )
      state.slips[index] = Object.assign(state.slips[index], {
        note: action.payload?.note,
      })
      setSlipsInCookies(state.slips)
    },
    toggleBetslips: (state, action) => {
      if (action.payload) {
        state.betslipsOpen = action.payload
        return
      }
      state.betslipsOpen = !state.betslipsOpen
    },
    toggleBetSlipsMobile: (state, action) => {
      if (action.payload) {
        state.betslipsOpenMobile = action.payload
        return
      }
      state.betslipsOpenMobile = !state.betslipsOpenMobile
    },
    updatefetchBets: (state, action) => {
      state.fetchBets = action.payload
    },
    firebaseallUserBets: (state, action) => {
      state.allUserBets = action.payload
    },

    updateMultipleSlips: (state, action) => {
      const updatedSlips = action.payload
      if (updatedSlips.length > 0) {
        updatedSlips.forEach((updatedSlip) => {
          const index = state.slips.findIndex(
            (slip) => slip.id === updatedSlip.id,
          )
          if (index > -1) {
            state.slips[index] = Object.assign(state.slips[index], updatedSlip)
          }
        })
        setSlipsInCookies(state.slips)
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(placeBet.fulfilled, (state, action) => {
        successMsg('Bet Placed Successfully')
        state.betPlaceLoad = false
        state.authError = false
      })
      .addCase(placeBet.pending, (state, action) => {
        state.betPlaceLoad = true
      })
      .addCase(placeBet.rejected, (state, action) => {
        if (
          action?.payload?.response?.data?.message ===
            'Invalid authToken provided' ||
          action?.payload?.response?.data?.message ===
            'Missing or invalid authentication' ||
          action?.payload?.response?.data?.message === 'INVALID_ID_TOKEN'
        ) {
          const errorData =
            'Your session has expired. The session is refreshed and played again, please wait...'
          errorMsg(errorData)
          state.authError = true
        } else {
          errorMsg(
            action?.payload?.response?.data?.message ||
              'Invalid betslips are removed',
          )
        }
        state.betPlaceLoad = false
      })
      .addCase(getPlacedBets.fulfilled, (state, action) => {
        state.placedBets = action?.payload || []
      })
      .addCase(getPlacedLiveGames.fulfilled, (state, action) => {
        state.placedLiveGames = action?.payload || []
      })
  },
})

export default betSlipsSlice.reducer

export const {
  addBetSlip,
  removeBetSlip,
  updateSingleBetPrice,
  updateBetSlips,
  clearBetSlips,
  filterBetSlips,
  changeOddsDisplayType,
  changeViewsDisplayType,
  changeMenuDisplayType,
  removeBetSlipSecondary,
  clearBetsData,
  updateTotalBetPrice,
  addNoteToSingle,
  toggleBetslips,
  updatePlacedBets,
  toggleBetSlipsMobile,
  updateCounts,
  updatefetchBets,
  updatePastBets,
  updateMultipleSlips,
  firebaseallUserBets,
  setIsParlay,
  updateParlaySlips,
  updateParlayBetPrice,
} = betSlipsSlice.actions
