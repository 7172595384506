import * as React from 'react'
const PlayingCardClover = (props) => (
  <svg
    height="800px"
    width="800px"
    id="_x32_"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path
        fill="#fff"
        d="M398.324,0H113.676C94.44,0,78.844,15.595,78.844,34.832v442.335c0,19.237,15.596,34.832,34.833,34.832 h284.648c19.237,0,34.832-15.595,34.832-34.832V34.832C433.156,15.595,417.561,0,398.324,0z M156.843,122.11h-14.779 c2.289-6.313,3.497-13.698,4.054-21.458c-3.233,3.872-8.095,6.356-13.532,6.356c-9.72,0-17.633-7.904-17.633-17.638 c0-9.716,7.912-17.62,17.633-17.62c0.965,0,1.918,0.076,2.846,0.22c-2.268-2.952-3.608-6.653-3.608-10.669 c0-9.708,7.908-17.629,17.638-17.629c9.717,0,17.621,7.921,17.621,17.629c0,4.016-1.34,7.718-3.603,10.669 c0.923-0.145,1.88-0.22,2.842-0.22c9.725,0,17.633,7.904,17.633,17.62c0,9.734-7.908,17.638-17.633,17.638 c-5.433,0-10.296-2.484-13.532-6.356C153.346,108.412,154.554,115.797,156.843,122.11z M295.196,312.023 c-12.622,0-23.921-5.752-31.442-14.762c1.288,18.012,4.101,35.199,9.414,49.833h-34.33c5.322-14.634,8.134-31.821,9.419-49.833 c-7.517,9.01-18.816,14.762-31.438,14.762c-22.59,0-40.964-18.378-40.964-40.968c0-22.572,18.374-40.95,40.964-40.95 c2.25,0,4.462,0.188,6.615,0.537c-5.254-6.875-8.381-15.468-8.381-24.785c0-22.572,18.374-40.95,40.959-40.95 c22.581,0,40.954,18.378,40.954,40.95c0,9.317-3.131,17.91-8.385,24.785c2.153-0.349,4.36-0.537,6.615-0.537 c22.589,0,40.95,18.378,40.95,40.95C336.146,293.645,317.784,312.023,295.196,312.023z M379.419,463.818 c-5.42,0-10.287-2.484-13.529-6.356c0.553,7.76,1.77,15.145,4.058,21.458h-14.787c2.289-6.313,3.505-13.698,4.058-21.458 c-3.242,3.872-8.1,6.356-13.528,6.356c-9.734,0-17.634-7.904-17.634-17.638c0-9.716,7.9-17.62,17.634-17.62 c0.97,0,1.914,0.076,2.841,0.22c-2.254-2.952-3.607-6.653-3.607-10.669c0-9.707,7.904-17.628,17.637-17.628 c9.716,0,17.629,7.921,17.629,17.628c0,4.016-1.361,7.718-3.607,10.669c0.918-0.144,1.863-0.22,2.833-0.22 c9.733,0,17.629,7.904,17.629,17.62C397.048,455.914,389.152,463.818,379.419,463.818z"
      />
    </g>
  </svg>
)
export default PlayingCardClover
