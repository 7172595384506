import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import store from '@/redux/store'
import { ToastContainer } from 'react-toastify'
import ErrorBoundary from '@/components/pages-partials/ErrorBoundary'
import {
  firebaseGetAuthorizedUser,
  firebaseOnIdTokenChange,
} from '@/firebase/utils'
import ProtectedRoute from '@/components/protectedRoute'
import { HighlightInit } from '@highlight-run/next/client'
import Script from 'next/script'
import Head from 'next/head'
import { APP_URL, growthbookClientKey } from '@/constants/environment'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import { useRouter } from 'next/router'
import { LanguageProvider } from '@/hooks/languageContext'
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import '@/styles/globals.css'
import 'react-toastify/dist/ReactToastify.css'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'

const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: growthbookClientKey,
  enableDevMode: true,
  subscribeToChanges: true,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log('Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.key,
    })
  },
})

// code commented for cookie save

// function getLangCookie(name) {
//   const value = `; ${document.cookie}`
//   const parts = value.split(`; ${name}=`)
//   if (parts.length === 2) return parts.pop().split(';').shift()
// }

function App({ Component, pageProps }) {
  const router = useRouter()
  const [queryClient] = useState(() => new QueryClient())

  useEffect(() => {
    const unsubscribe = firebaseGetAuthorizedUser()
    firebaseOnIdTokenChange()
    // setLocale(getLangCookie('preferredLocale')
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    growthbook.loadFeatures()

    growthbook.setAttributes({
      // TODO: give more info about the customer
      // id: '123',
      // loggedIn: true,
      // deviceId: 'abcdef123456',
      // employee: true,
      // company: 'acme',
      // country: 'US',
      browser: navigator.userAgent,
      url: router.pathname,
    })
  }, [router.pathname])

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <title>Lilbit</title>
        <link href="/favicon.ico" rel="shortcut icon" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="description"
          content="Find the best casino, sports, bets, plinko, and games on Lilbit. Sign up now to get started."
        />
        <meta
          name="keywords"
          content="casino, sports, bets, plinko, games, betting, gambling, online casino, sportsbook, esports, virtual sports, live casino, table games, slots, roulette, blackjack, poker, baccarat"
        />
        <meta
          property="og:title"
          content="Best Casino, Sports, Bets, Plinko, and Games | Lilbit"
        />
        <meta
          property="og:description"
          content="Find the best casino, sports, bets, plinko, and games on Lilbit. Sign up now to get started."
        />
        <meta property="og:image" content="/img/OpenGraph.png" />
        <meta property="og:url" content={APP_URL} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="lilb.it" />
        <meta property="twitter:url" content={APP_URL} />
        <meta
          name="twitter:title"
          content="Best Casino, Sports, Bets, Plinko, and Games | Lilbit"
        />
        <meta
          name="twitter:description"
          content="Find the best casino, sports, bets, plinko, and games on Lilbit. Sign up now to get started."
        />
        <meta
          name="twitter:image"
          content="https://lilb.it/img/OpenGraph.png"
        />

        <link rel="canonical" href={APP_URL} />
      </Head>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <LanguageProvider>
              <GrowthBookProvider growthbook={growthbook}>
                <Provider store={store}>
                  <ProtectedRoute>
                    <Component {...pageProps} />
                  </ProtectedRoute>
                  <ToastContainer />
                </Provider>
              </GrowthBookProvider>
            </LanguageProvider>
          </Hydrate>
        </QueryClientProvider>
        {process.env.NEXT_PUBLIC_VERCEL_ENV && (
          <HighlightInit
            projectId={'jgo19qdl'}
            tracingOrigins
            version={process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA}
            networkRecording={{
              enabled: true,
              recordHeadersAndBody: true,
            }}
          />
        )}
      </ErrorBoundary>
      {process.env.NEXT_PUBLIC_VERCEL_ENV && (
        <>
          <Script src="https://www.googletagmanager.com/gtag/js?id=G-5YYSBTFXPS" />
          <Script id="google-analytics">
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
  
            gtag('config', 'G-5YYSBTFXPS');
          `}
          </Script>
        </>
      )}
    </>
  )
}

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
}

export default App
