import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  getBalance,
  getMnemonicFromApi,
  getwalletBal,
  getHistory,
} from '@/services/relysia-queries'

export const getInternalWalletBalance = createAsyncThunk(
  'wallet/getInternalWalletBalance',
  async (request, thunkAPI) => {
    try {
      const response = await getBalance()
      if (response?.data && !response.error) return response.data
      else throw response.error
    } catch (error) {
      console.log('error', error)
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getMnemonic = createAsyncThunk(
  'wallet/getMnemonic',
  async (request, thunkAPI) => {
    try {
      const response = await getMnemonicFromApi()
      if (response?.data) return response.data
    } catch (error) {
      console.log('error', error)
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const refreshWalletDetails = createAsyncThunk(
  'wallet/refreshWalletDetails',
  async (request, thunkAPI) => {
    try {
      const response = await getwalletBal()
      if (response) return response?.toFixed(4)
    } catch (error) {
      console.log('error', error)
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getHistoriesThunk = createAsyncThunk(
  'wallet/getHistoriesThunk',
  async (request, thunkAPI) => {
    try {
      const response = await getHistory(request)
      if (response?.data && !response.error) return response.data
      else throw response.error
    } catch (error) {
      console.log('error in getHistoriesThunk', error)
      return thunkAPI.rejectWithValue(error)
    }
  },
)

let internalWalletObj = {
  balanceBsv: '0',
  balanceUsd: '0',
  paymail: null,
  address: null,
  mnemonic: null,
  isPending: false,
  error: '',
  walletToken: '',
  walletId: '',
  histories: [],
  historyLoading: false,
  historyError: '',
  historyNextToken: '1',
}

const initialState = {
  handcash: {
    limitLink: '',
    warningModal: false,
  },
  isPending: false,
  internalWallet: internalWalletObj,
  connectModal: false,
  handCashModal: false,
  depositModal: false,
}

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    resetWalletDetails: (state) => {
      state.handcash = {
        limitLink: '',
        warningModal: false,
      }
      state.internalWallet = internalWalletObj
    },
    updateInternalWalletBalance: (state, action) => {
      state.internalWallet = {
        ...state.internalWallet,
        balanceBsv: action.payload.balanceBsv,
        balanceUsd: action.payload.balanceUsd,
      }
    },
    handleConnectModal: (state, action) => {
      state.connectModal = action.payload
    },
    handleWarningModal: (state, action) => {
      console.log(action)
      state.handcash = {
        warningModal: action.payload.warningModal,
        limitLink: action.payload.limitLink,
      }
    },
    updateWalletData: (state, action) => {
      state.internalWallet.paymail = action.payload.paymail
      state.internalWallet.address = action.payload.address
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInternalWalletBalance.fulfilled, (state, action) => {
        const { balanceUsd, balanceBSV } = action.payload
        state.internalWallet.isPending = false
        state.internalWallet.balanceBsv = balanceBSV
        state.internalWallet.balanceUsd = balanceUsd
      })
      .addCase(getInternalWalletBalance.pending, (state, action) => {
        state.internalWallet.isPending = true
      })
      .addCase(getInternalWalletBalance.rejected, (state, action) => {
        state.internalWallet.isPending = false
        state.internalWallet.error = action.payload
      })
      .addCase(getMnemonic.fulfilled, (state, action) => {
        state.internalWallet.mnemonic = action.payload
        state.internalWallet.isPending = false
      })
      .addCase(refreshWalletDetails.fulfilled, (state, action) => {
        state.internalWallet.balanceBsv = action.payload
        state.internalWallet.isPending = false
      })
      .addCase(refreshWalletDetails.pending, (state, action) => {
        state.internalWallet.isPending = true
      })
      .addCase(refreshWalletDetails.rejected, (state, action) => {
        state.internalWallet.isPending = false
        state.internalWallet.error = action.payload
      })
      .addCase(getHistoriesThunk.fulfilled, (state, action) => {
        const prev = [...state.internalWallet.histories] || []
        const historyData = action.payload.histories
        const uniques = historyData.filter((history) => {
          return !prev.some((prevHist) => prevHist.txId === history.txId) || []
        })
        const nextToken = action?.payload?.meta?.nextPageToken
          ? action?.payload?.meta?.nextPageToken
          : null
        state.internalWallet.histories = [...prev, ...uniques]
        state.internalWallet.historyNextToken = nextToken
        state.internalWallet.historyLoading = false
      })
      .addCase(getHistoriesThunk.pending, (state, action) => {
        state.internalWallet.historyLoading = true
      })
      .addCase(getHistoriesThunk.rejected, (state, action) => {
        state.internalWallet.historyLoading = false
        state.internalWallet.historyError = action.payload
      })
  },
})

export default walletSlice.reducer
export const {
  resetWalletDetails,
  updateInternalWalletBalance,
  handleConnectModal,
  handleWarningModal,
  updateWalletData,
} = walletSlice.actions
